import React from 'react';
import '../css/index.css'


const demoStyle = { padding: 10, fontSize: 18,lineHeight: '1.5em' }

class Introduction extends React.Component {

    state = {
        show: 1
    }

    clickDemo = (e, type) => {
        console.log(type)
        this.setState({
            show: type
        })
    }

    render() {
        const { show } = this.state
        const { data } = this.props
        return (
            <div className="introduction">
                <div className="container flexUl">
                    <ul style={{ fontSize: 24 }}>
                        {data.introduction.map(x => {
                            return (
                                <li onClick={(e) => this.clickDemo(e, x.id + 1)} style={show == x.id + 1 ? { borderBottom: '3px solid #3f51b5' } : {}} key={`${x.id}--${x.title}`}>{x.title}</li>
                            )
                        })}
                    </ul>
                    {data.introduction.map(x => {
                        return (
                            show == x.id + 1 && <div key={x.value} style={demoStyle} dangerouslySetInnerHTML={{__html: x.value}}>
                                
                            </div>
                        )
                    })}
                </div>
            </div >
        );
    }
}

export default Introduction;
