import React from 'react';
import '../css/index.css';
import '../css/card.css';
import { Link } from 'react-router-dom';


const style = { background: '#0092ff', padding: '8px 0' };

class Card extends React.Component {

    render() {
        const { data, history } = this.props
        const src = history.location.pathname !== "/" ? (history.location.pathname[history.location.pathname.length - 1] === "/" ? history.location.pathname.slice(0, history.location.pathname.length - 1) : history.location.pathname) : 'home'
        return (
            <div className="card">
                <div className="container cardFlex" style={{ marginBottom: 40 }}>
                    {data && data.data.length > 0 && data.data.map(x => {
                        return (
                            <div key={x.id + '-' + x.title}>
                                <img style={{ marginBottom: 20 }} src={require(`../assets/image/${x.img}`)} />
                                <span>{x.title}</span>
                                {x.isImage ? <p style={{ marginTop: 17 }}><span className="image">IMAGE</span></p> : <p><span>FEATURED</span></p>}
                                <Link
                                    to={`${src}s/details?${x.id}`}>
                                    開始體驗
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}


export default Card;