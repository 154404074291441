import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom';

import '../css/index.css'

class Knowledge extends React.Component {

    state = {
        show: false,
    }

    componentDidMount = () => {
    }

    // 

    render() {
        const { data } = this.props
        return (
            <div className="knowledge" style={{ padding: '20px 0' }}>
                <div className="container">
                    <h1 style={{ fontSize: '1.5rem', fontWeight: 600 }}>
                        知识卡片：
                    </h1>
                    <ul style={{ paddingLeft: 40 }} className="cardFlex">
                        {data.knowledge.map(x => {
                            return (
                                <li key={`${x.id}-${x.title}`}  style={{ background: '#0166ad', color: "#fff", padding: 20,marginBottom:25,marginTop:25 }}>
                                    <h2 style={{ fontSize: 24,color:'#fff' }}>{x.title}</h2>
                                    <p style={{ minHeight: 100,lineHeight:'2rem',fontSize:15,fontWeight:'300' }}>{x.knowDec}</p>
                                    <p style={{ borderTop: '1px solid rgba(160,160,160,0.2)', color: '#ffab40', paddingTop: 25 }}>
                                        <a style={{ color: '#ffab40' }} href={x.src}>Wiki</a>
                                    </p>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Knowledge;
