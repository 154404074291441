import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom';

import '../css/index.css'

class Resources extends React.Component {

    state = {
        show: false,
    }

    componentDidMount = () => {
    }

    // 

    render() {
        const { data } = this.props
        return (
            <div className="resources" style={{ padding: '20px 0' }}>
                <div className="container">
                    <h1 style={{ fontSize: '1.5rem', fontWeight: 600 }}>
                        參考資料：
                    </h1>
                    <ul style={{ paddingLeft: 40,fontSize:16 }}>
                        {data.resources.map(x => {
                            return (
                                <li key={`${x.src}`} style={{padding:'10px 0'}}>
                                    <a href={x.src}>{x.title} </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Resources;
